<script>
export default {
  name: 'ArticleActionsCell',
  data: function () {
    return {
      articleData: false,
    };
  },
  beforeMount() {
    this.renderValue = this.params.value;
  },
  mounted() {
    if (this.params.data) {
      this.articleData = this.params.data
    }
  },
  methods: {
    showEditArticleModal(){
      this.$store.dispatch('articles/openEditArticleModal', this.articleData);
    },
    deActivateArticle() {
      this.$store.dispatch('articles/openDeactivateModal', this.articleData);
    }
  }
};
</script>

<template>
  <div class="articleDisplay">
    <b-button size="sm" variant="primary" @click="showEditArticleModal" class="articleDisplay__button"
              title="Edit Article "
    >
      <i class="bx bx-edit"></i>
    </b-button>
    <b-button size="sm" variant="danger" @click="deActivateArticle" class="articleDisplay__button mx-1"
              title="De-activate Article"><b-icon-x-square></b-icon-x-square></b-button>
  </div>
</template>

<style lang="scss">
.articleDisplay {
  display: flex;
  align-items: center;

  &__text {
    margin-right: 10px;
    display: inline-block;
  }

  &__button {
    background: transparent;
    font-weight: bold;
    border: 0;
    font-size: 16px;
    height: 27px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #000;
    }

    svg {
      fill: #000;
      width: 16px;
    }

    &:hover {
      i {
        color: #fff;
      }

      svg {
        fill: #fff;
      }
    }
  }
}
</style>